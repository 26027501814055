<template>
  <div>
    <div class="container">
      <img src="@/assets/img/mock/ResumeInterview/banner@2x.png" alt="" />
      <img src="@/assets/img/mock/ResumeInterview/1@2x.jpg" alt="" />
      <img src="@/assets/img/mock/ResumeInterview/2@2x.jpg" alt="" />
      <img src="@/assets/img/mock/ResumeInterview/3@2x.png" alt="" />
      <img src="@/assets/img/mock/ResumeInterview/4@2x.png" alt="" />
    </div>
	<div class="prices">
	  <span class="usdPrice">${{ classInfo.usdAmount }}/</span>
	  <span class="rmbPrice">￥{{ classInfo.amount }} </span>
	  <div class="buy" @click="provideDialogVisible = true">立即购买</div>
	</div>
	<el-dialog :visible.sync="provideDialogVisible" width="calc(100vw - 30px)">
	  <div class="provideContainer">
	    <div class="type" @click="chooseCombo(classInfo.details[0])">
	      <img src="@/assets/img/mock/down.png" alt="" />
	      <div class="title">{{ classInfo.details[0].courseName }}</div>
	      <div class="description">{{ classInfo.details[0].brief }}</div>
	      <div class="price">${{ classInfo.details[0].usdAmount }}</div>
	    </div>
	    <div class="type" @click="chooseCombo(classInfo.details[1])">
	      <img src="@/assets/img/mock/pen.png" alt="" />
	      <div class="title">{{ classInfo.details[1].courseName }}</div>
	      <div class="description">{{ classInfo.details[1].brief }}</div>
	      <div class="price">${{ classInfo.details[1].usdAmount }}</div>
	    </div>
	  </div>
	</el-dialog>
  </div>
</template>

<script>
import mockMix from "@/utils/mockMix";
import { setStorageForArray } from "@/utils/index";
export default {
  data() {
    return {
		provideDialogVisible:false
	};
  },
  mounted() {},
  mixins: [mockMix],
  methods: {
	    chooseCombo(item) {
			setStorageForArray("order", [item]);
			this.$router.push("/pay/confirm");
		},
	  
  },
};
</script>
<style scoped lang="scss">
	@import url("../../assets/css/course.scss");
.container {
	padding-top: 54px;
  img {
    width: 100%;
    vertical-align: top;
  }
}

.provideContainer {
  .type {
    width: 100%;
	box-sizing: border-box;
	margin: 10px 0;
    padding: 15px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #757575;
    text-align: center;
    cursor: pointer;
    img {
      width: 20px;
      margin-bottom: 10px;
    }
    &:hover {
      border-color: rgba(10, 122, 255, 0.85) !important;
      .title,
      .description {
        color: rgba(10, 122, 255, 0.85) !important;
      }
    }
    .title {
      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 27px;
      margin-bottom: 10px;
    }
    .description {
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #757575;
      line-height: 21px;
      margin-bottom: 10px;
    }
    .price {
      font-size: 24px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ff3b30;
      line-height: 36px;
    }
  }
}
/deep/ .el-dialog__body{
	padding: 10px 20px;
}
</style>